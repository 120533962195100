import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';

const FadeInSection = ({
  children,
  override,
  leftAlign,
  flex
}) => {
  const domRef = useRef();
  
  const [isVisible, setVisible] = useState(false);

  const getClass = (isVisible) => {
    if (override) {
      return isVisible ? 'first-visible first' : 'first'
    }

    return isVisible ? 'is-visible transform' : 'transform'
  }

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisible(true);
        observer.unobserve(domRef.current);
      }
    }, {
      rootMargin: override ? '-220px' : '-10px',
    });
    
    observer.observe(domRef.current);
    
    return () => typeof domRef.current === 'Element' && observer.unobserve(domRef.current);
  }, []);

  return (<Section leftAlign={leftAlign} ref={ domRef } flex={flex} className={getClass(isVisible)}>{ children }</Section>);
};

const Section = styled.section`
  max-width: 50em;
  font-family: acumin-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2em;
  width: ${props => props.leftAlign ? "50em" : "auto"};
  margin: 20px 0px;
  display: ${props => props.flex ? "flex" : "block"};

  @media (max-width: 1100px) {
    width: 40em;
  }

  @media (max-width: 800px) {
    font-size: 1em;
    line-height: 1.4em;
    width: 30em;
  }

  @media (max-width: 500px) {
    font-size: 1.125rem;
    max-width: 20em;
    margin: 20px 0px;
    width: ${props => props.leftAlign ? "20em" : "auto"};
  }

  @media (max-width: 400px) {
    max-width: 18em;
  }

  @media (max-width: 300px) {
    max-width: 16em;
    font-size: 0.9em;
  }
`;

export default FadeInSection;