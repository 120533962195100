import GatsbyLink from 'gatsby-link';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useHover from "../hooks/useHover";

const Navigation = ({ to, visible }) => {  
  const [hovered, eventHandlers] = useHover();
  const [imgIndex, setImgIndex] = useState(5);
  const [firstRun, setFirstRun] = useState(true);

  const delay = (milliseconds) => {
      return new Promise(resolve => {
          setTimeout(resolve, milliseconds);
      });
  }



  useEffect(() => {
    async function runAnimationOneSecond() {
      await delay(1000);
      setFirstRun(false);
    }

    runAnimationOneSecond();
  }, []);

  useEffect(() => {
    if (hovered || firstRun) {
      const intervalId = setInterval(() => {
        setImgIndex(imgIndex > 5 ? 1 : imgIndex + 1);
      }, 100);
      return () => clearInterval(intervalId);
    }
  }, [hovered, imgIndex, firstRun]);

  return (
    <Header className={visible ? "show" : "hide"}>
      <HomeLink {...eventHandlers} to={to} >
        <Image src={`./${imgIndex}.png`} />
        &nbsp;&nbsp;{to === "/" ? "Home" : "About"}
      </HomeLink>
     </Header>
  );
};

const Header = styled.div`
  position: fixed;
  height: 100px;
  padding: 48px;
  width: 100%;

  display: flex;
  flex-direction: column;

  z-index: 1000;

  @media (max-width: 500px) {
    padding: 24px;
  }
`;

const HomeLink = styled(GatsbyLink)`
  font-family: acumin-pro, sans-serif;
  height: 48px;
  color: black;

  display: flex;
  justify-content: center;
  align-items: center;

  width: fit-content;
  text-decoration: none;
`;

const Image = styled.img`
  height: 48px;
  width: 48px;
`;

export default Navigation;